import React from "react"
import { getQuantityValue } from "../../utilities/eventTools"
import { getTotalQuantity } from "../../utilities/eventTools"

const PassSelection = ({
  ticketsAvailable,
  quantityOfPasses,
  setQuantityOfPasses,
  ticketData,
}) => {
  const handleSlidingScaleUpdate = (e, amount, id, ticketType, ticketData) => {
    const quantity = e.target.value
    const newQuantityOfPasses = {
      ...quantityOfPasses,
      [id]: { quantity, id, ticketType },
    }
    const totalQuantityOfSelected = getTotalQuantity(newQuantityOfPasses)
    if (totalQuantityOfSelected <= ticketsAvailable) {
      setQuantityOfPasses(newQuantityOfPasses)
    }
  }

  return (
    <div className="tickets-field">
      <div className="TBA22-pass-details">
        <h3>Pass details</h3>
        <div className="expanding-pass-details">
          <input type="checkbox" id="pass1"/>
          <label htmlFor="pass1">Patron Pass (All Access + VIP)</label>
          <p>($250 is tax-deductible) Includes priority admission to all performances, entry to a special VIP event, festival concierge service, and full access to exhibitions and TBA:24 programs. Plus, a donation to PICA in support of TBA.</p>
        </div>
        <div className="expanding-pass-details">
          <input type="checkbox" id="pass3"/>
          <label htmlFor="pass3">Full Pass</label>
          <p>Includes admission to all performances and full access to TBA:24 exhibitions and programs.</p>
        </div>
        <div className="expanding-pass-details">
          <input type="checkbox" id="pass4"/>
          <label htmlFor="pass4">One-Weekend Pass</label>
          <p>Includes access to all performances either during Weekend 1 (Sept. 5–8), <u>or</u> Weekend 2 (Sept. 12–15), <u>or</u> Weekend 3 (Sept. 19–22) of TBA.</p>
        </div>
      </div>
      <h3>Passes</h3>
      <div className="field qty-field">
        <p className="-sm_lineheight">
          <b>Dates:</b> For TBA:24 events between September 5-22 2024 <br />
        </p>

        <div className="ticket-sliding-scale">
          {ticketData &&
            ticketData.prices
              .sort((a, b) => b.amount - a.amount) // sort by lowest
              .map((ticket) => {
                const value = getQuantityValue(ticket.amount, quantityOfPasses, ticket.id)

                return (
                  <div className="field" key={ticket.id}>
                    <input
                      type="number"
                      value={value || 0}
                      onChange={(e) =>
                        handleSlidingScaleUpdate(
                          e,
                          ticket.amount,
                          ticket.id,
                          ticket.ticketType.id
                        )
                      }
                    />
                    <label>
                      ${ticket.amount}: {ticket.ticketType.name}
                    </label>
                  </div>
                )
              })}
        </div>
      </div>
    </div>
  )
}

export default PassSelection
