import React from 'react'
import { Container, Col, Row } from 'reactstrap'
import TBAArtistBlockFrame from '../../components/TBAArtistBlockFrame'
import PicaButton from '../../components/PicaButton'
import { Link } from "react-router-dom"

import TBA24_bg from '../../img/TBA24-masthead.jpg'
import sponsor1 from '../../img/sponsors-natl_endowment_arts.png'
import sponsor2 from '../../img/sponsors-westaf.png'
import TBA23_bg from '../../img/Time-Released-23-main_header.gif'
import TBA22_figure from '../../img/tba22-banner-figure.png'
import T from '../../img/T.svg'
import B from '../../img/B.svg'
import A from '../../img/A.svg'

export default function TBA (props) {
  return (

<Container className='TBA_new'>
  <header className="tba-header" style={{backgroundImage: 'url(' + TBA24_bg + ')'}}>
    <div>
      <ul className="TBA-animation">
        <li className="T"></li>
        <li className="T"></li>
        <li className="T"></li>
        <li className="T"></li>
        <li className="T"></li>
        <li className="B"></li>
        <li className="A"></li>
      </ul>
      <p className="TBA24-banner-credit">Autumn Knight | Photo: Angel Origgi</p>
    </div>
    <div>
      <ul className='TBA24-footer-list'>
        <li>Time</li>
        <li>Based</li>
        <li>Art</li>
        <li>Festival</li>
      </ul>
      <ul className='TBA24-subfooter-list'>
        <li>2024</li>
        <li>2024</li>
        <li>2024</li>
        <li>2024</li>
        <li>2024</li>
        <li>2024</li>
        <li>2024</li>
      </ul>
      <ul className="TBA24-dates">
        <li>Sept. 5</li>
        <li>→</li>
        <li>22</li>
      </ul>
    </div>
  </header>

  <section id='overview'>
    <h2>SEPTEMBER 5 – 22, 2024 <br/>PORTLAND, OREGON, USA</h2>
    <p>TBA is back, and we are beyond excited to welcome you to Portland’s favorite celebration of contemporary art! This festival was created in 2003 as a way to bring artists and audiences together. <b>Everyone remembers their first TBA:</b> the first time a performance moved them, the first time they sweat furiously on one of our dance floors, the first time they felt at home in a crowd and truly welcomed in an institutional space. As TBA enters its 21st year, <b>it’s time again for YOU to come with us.</b> It’s time to meet a new artist over drinks at PICA. It’s time to run into an old friend and embrace. It’s time to encounter the unexpected and <b>experience what you haven’t before.</b></p>
    <p>TBA:24 has been thoughtfully built to span three weeks, allowing audience members to be fully immersed in a wide variety of programs on weekends, with some breathing room between the busier days. Join us as we gather with a host of renowned international, national, and regional artists for World Premieres with local partners and at accessible sites and spaces across the city. Get curious about some of the most vital ideas and conversations happening in our world today. Champion artists and art. Come, <b>be part of TBA:24</b> — a festival like no other.</p>
  </section>

  <section id="TBA-20k">
    <h2>TAKE A SEAT</h2>
    <h3>Help us raise money for new audience chairs and become a part of PICA history!</h3>
    <br/>
    <p>As a PICA supporter, you stand with great artists—and this year, there’s no better way to stand with us than to sit! From today until the end of TBA:24, when you give a gift of $100 or more, we will honor your contribution by naming a chair after you. That’s right, for $100 your name can be forever recognized on-site at PICA! Additionally, thanks to a matching gift from a generous donor, your impact is now doubled when you give during TBA:24.</p>
    <p>Your support of the Takea a Seat campaign has a ripple effect beyond audience chairs—it allows us to amplify the visions of contemporary artists and connect more viewers with ambitious, compelling work.</p>
    <Link className="button inverted" to="/support/donations">Donate Here</Link>
  </section>


  <section id="TBA-passes">
    <h1>TBA:24 Pass Info</h1>
    <p><span class="-highlight">Reservations are required for all ticketed programs regardless of pass purchase. To RVSP to a TBA:24 event, click the Google Form link in your emailed receipt.</span></p>
    <div className="pass-info">
      <h3><a href="/tba/passes">Patron Pass (All Access + VIP)</a></h3>
      <h4>$500</h4>
      <p>($250 is tax-deductible) Includes priority admission to all performances, entry to a special VIP event, festival concierge service, and full access to exhibitions and TBA:24 programs. Plus, a donation to PICA in support of TBA.</p>
    </div>

    <div className="pass-info">
      <h3><a href="/tba/passes">Full Pass</a></h3>
      <h4>$300</h4>
      <p>Includes admission to all performances and full access to TBA:24 exhibitions and programs.</p>
    </div>

    <div className="pass-info">
      <h3><a href="/tba/passes">One-Weekend Pass</a></h3>
      <h4>$100</h4>
      <p>Includes access to all performances either during Weekend 1 (Sept. 5–8), <u>or</u> Weekend 2 (Sept. 12–15), <u>or</u> Weekend 3 (Sept. 19–22) of TBA.</p>
    </div>

  </section>


  <section id='TBAprograms'>
    <div className="TBA-buttons">
      <a className="button inverted" href="/tba/passes">Passes</a>
      <a className="button inverted" href="#faq">FAQ &rarr;</a>
      <Link className="button inverted" to="/support/donations">Donate &rarr;</Link>
    </div>

    <Row>
      <TBAArtistBlockFrame />
    </Row>
  </section>

  <section id='bxo'>
    <h2 className="-center">TBA:24 BOX OFFICE</h2>
    <h2 className="-center -blue">Email <a href="mailto:boxoffice@pica.org" target="_blank">boxoffice@pica.org</a> or call <a href="tel:15032247422">503-&#x2060;224-&#x2060;PICA</a></h2>
    <h3 className="-center">Box Office Open <br/>Aug 5 - Sept 22</h3>
    <hr />

    <div className="bxo-flex">
      <div className="bxo-col">
        <h2>Before the Festival</h2>
        <p>Aug 5 – Sept 4: The Box Office can be reached via email or phone from 12:00 – 4:00 PM PDT. Closed on weekends and Labor Day.</p>

      </div>
      <div className="bxo-col">
        <h2>During the Festival</h2>
        <p>Sept 5 – 22: The Box Office will be open each day of the Festival via email or phone. Box Office staff will be available in person one hour before each event.</p>
      </div>
    </div>
  </section>

  <section id='passInfo'>
    <div className="TBA-buttons">
      <a className="button inverted" href="/tba/passes">Passes</a>
      <a className="button inverted" href="#faq">FAQ &rarr;</a>
      <Link className="button inverted" to="/support/donations">Donate &rarr;</Link>
    </div>

  </section>

  <section id="faq">
    <h1 className="center">TBA:24 FAQ</h1>
    <hr />
    <div className="faq-flex">
      <div className="faq-col">

        <h4>WHERE IS THE BOX OFFICE?</h4>
        <p>From Aug. 5 – Sept. 22, the Box Office can be reached by email at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> or by phone at <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>. The Box Office will be open every day of the Festival via email or phone. Box Office staff will be available in-person one hour before each event.</p>

        <h4>HOW DO I BUY A TBA:24 PASS?</h4>
        <p>You can buy a TBA:24 pass from our website at pica.org/tba, by emailing the Box Office at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a>, or by calling <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>.</p>

        <h4>DO I NEED A PASS TO ATTEND TBA:24?</h4>
        <p>No. You can purchase individual tickets for each event, or you can buy a pass for varying levels of Festival access. </p>
        <p><b>PASS LEVELS:</b></p>
        <ul>
          <li>Patron Pass (All Access + VIP) - $500</li>
          <li>Full Pass - $300</li>
          <li>One-Weekend Pass - $100</li>
        </ul>

        <h4>WHAT DOES MY PASS INCLUDE?</h4>
        <ul>
          <li>
            <p>
              <b>Patron Pass (All Access + VIP)</b> <br/>
              <b>$500 ($250 tax deductible)</b> <br/>
              Includes priority admission to all performances, entry to a special VIP event, festival concierge service, and full access to exhibitions and TBA:24 programs. Plus, a donation to PICA in support of TBA. </p>
          </li>
          <li>
            <p>
              <b>Full Pass</b> <br/>
              <b>$300</b> <br/>
              Includes admission to all performances and full access to TBA:24 exhibitions and programs. Reservations are required for all ticketed programs. Please email the Box Office at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> to make reservations.</p>
          </li>
          <li>
            <p>
              <b>One-Weekend Pass</b> <br/>
              <b>$100</b> <br/>
              Includes access to all performances either during Weekend 1 (Sept. 5–8), <u>or</u> Weekend 2 (Sept. 12–15), <u>or</u> Weekend 3 (Sept. 19–22) of TBA. Reservations are required for all ticketed programs. Please email the Box Office at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> to make reservations.</p>
          </li>
        </ul>

        <h4>DO I NEED RESERVATIONS IF I HAVE A PASS?</h4>
        <p>Reservations are required for all ticketed programs regardless of pass purchase. To RVSP to a TBA:24 event, click the Google Form link in your emailed receipt.  </p>

        <h4>IS ANYTHING "OFF PASS"?</h4>
        <p>Yes. Tickets to Elbow Room's <i>Good Dang Weekend</i> is "off pass" and must be purchased separately. Please see pica.org/tba for details on each event, or contact the TBA Box Office by email at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> or call <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>.</p>

        <h4>WHAT IF I ARRIVE LATE FOR A SHOW?</h4>
        <p>As a general rule, there is no late seating. All shows begin promptly and as listed. For performances at PICA, you must check in no later than 15 minutes before curtain to ensure seating, even with a pass reservation. </p>

        <h4>WHEN CAN I BUY A TICKET?</h4>
        <p>We encourage you to purchase your tickets online, or by phone, in advance of each program. Ticket pre-sale ends two hours before each event. Tickets are available at the door each night for most events.</p>

      </div>

      <div className="faq-col">

        <h4>IF I RESERVE A SEAT FOR AN IN-PERSON PERFORMANCE AT PICA, DOES MY CHILD GET IN FOR FREE?</h4>
        <p>Children may attend for free if they can sit on your lap. Otherwise, you will need to reserve an additional seat. Please note while we welcome children and babies, we may have to ask you to wait in the lobby if there is any disturbance to the live performance.</p>

        <h4>WHICH SHOWS ARE APPROPRIATE FOR CHILDREN? CAN I BRING MY FAMILY?</h4>
        <p><i>Goner</i> by Marikiscrycrycry is recommended for viewers 16 and older, and <i>Carla Rossi’s Queer Horrors</i> by Anthony Hudson/Carla Rossi is recommended for viewers 18+.</p>
        <p>PICA supports freedom of speech for artists and the right of audiences to choose what they see and hear. Due to the immediate nature of live performance, we cannot prescreen all works for content. However, if you have specific concerns or questions, our Box Office staff can suggest guidance on shows.</p>

        <h4>CAN YOU REFUND OR EXCHANGE MY PASS?</h4>
        <p>All ticket and pass sales are final. There are no refunds or exchanges. In the event that we need to cancel/postpone a show due to unforeseen circumstances—such as wildfires, poor air quality, or shifts in the pandemic—we will still pay our artists and crew as promised. PICA is committed to paying artists and crew for scheduled performances. In the case of a cancellation or postponement, we ask audiences to please consider the cost of their pass as a donation to support fair wages, rather than requesting a refund. That said, if you would prefer to have your pass purchase refunded, you can contact the Box Office at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> or call <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>, and a Box Office representative will be happy to assist you. </p>

        <h4>ARE TBA:24 EVENTS ACCESSIBLE? </h4>
        <p>PICA is committed to accessibility as a value, ethic, and opportunity for learning, growth, and change. As we strive to create greater access to our programs and events, including the TBA Festival, we welcome feedback and dialogue from audiences and artists about their experiences and suggestions for improvement. To inform TBA staff of access needs, make requests for services, or offer feedback on our approach to access, please contact the TBA Box Office at <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> or <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>.</p>

        <h4>COVID-19</h4>
        <p>PICA strives to remain considerate of our staff, artists, and guests with varying levels of vulnerability, safety, and comfort. In this spirit, masks are recommended for TBA events. Anyone experiencing or exhibiting symptoms of COVID-19, cold, flu, etc., or who has been exposed to illness, is kindly asked to stay home. </p>

        <h4>TRANSPORTATION</h4>
        <p>PICA is located in the Boise-Eliot neighborhood of Northeast Portland. We are close to bus lines 4, 6, 17, and 44. Stops for the A Loop and B Loop Streetcar are approximately 0.3 miles from our building. The nearest MAX station is Rose Quarter Transit Center, which is approximately 0.5 miles away. On-street parking is available. Please be aware that PICA is in a residential area and therefore ask that all of our guests, staff, and audience members treat our neighborhood and neighbors, housed and unhoused, with the utmost respect.</p>

        <h4>HAVE QUESTIONS?</h4>
        <p>We are here to help! Email <a href="mailto:boxoffice@pica.org">boxoffice@pica.org</a> or call <a href="tel:503-224-7422">503-⁠224-⁠PICA</a>.</p>


      </div>

    </div>

  </section>

  <section className="sponsors-section">
    <hr />
    <h1>Sponsors</h1>
    <p>Time-Released is supported in part by the Western States Arts Federation (WESTAF) and the National Endowment for the Arts.</p>
    <div className="sponsors-flex">
      <img src={sponsor2} />
      <img src={sponsor1} />
    </div>
  </section>

<style dangerouslySetInnerHTML={{__html:`
/**************
TBA PAGE STYLES
**************/

/******** BREAKPOINT-A-PALOOZA *********/

@media screen and (min-width: 576px) and (max-width: 767px) {
/* breakpoint-sm */
  .collapsible-md {display:none !important;}
  .TBA_new section#programs .row {margin-left: 0; margin-right: 0;}
}
@media screen and (max-width: 575px) {
/* breakpoint-xs */
  .collapsible-xs, .collapsible-md {display:none !important;}
  .TBA_new.container {width: 100vw;}

  .TBA_new header {background-size: cover !important;}

}

`}} />

<span dangerouslySetInnerHTML={{__html:`
  <svg xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="duotone_yellow">
        <feColorMatrix type="matrix" result="grayscale" values="1 0 0 0 0
                                1 0 0 0 0
                                1 0 0 0 0
                                0 0 0 1 0">
        </feColorMatrix>
        <feComponentTransfer color-interpolation-filters="sRGB" result="duotone">
        <feFuncR type="table" tableValues="0 1"></feFuncR>
        <feFuncG type="table" tableValues="0 0.946"></feFuncG>
        <feFuncB type="table" tableValues="0 0"></feFuncB>
        <feFuncA type="table" tableValues="1 1"></feFuncA>
        </feComponentTransfer>
      </filter>
    </defs>
  </svg>
`}} />

    </Container>



  )
}
