import React from "react"
import { getQuantityValue } from "../../utilities/eventTools"

function SlidingScale({
  eventStatusLoading,
  ticketsAvailable,
  ticketData,
  slidingAmounts,
  handleSlidingScaleUpdate,
}) {
  return (
    <>
      <div className="field qty-field">
        <p className="-sm_lineheight">
          <b>PRICE:</b> Sliding Scale - Select Quantity Below <br />
          {eventStatusLoading !== "loading" &&
            eventStatusLoading !== "error" && (
              <span className="-sm_text">
                {ticketsAvailable && `${ticketsAvailable} tickets available`}
              </span>
            )}
        </p>

        <div className="ticket-sliding-scale">
          {ticketData.prices
            .sort((a, b) => b.amount - a.amount) // sort by highest price
            .map((ticket) => {
              const value = getQuantityValue(ticket.amount, slidingAmounts, ticket.id)

              return (
                <div className="field" key={ticket.id}>
                  <input
                    type="number"
                    value={value || 0}
                    onChange={(e) =>
                      handleSlidingScaleUpdate(
                        e,
                        ticket.amount,
                        ticket.id,
                        ticket.ticketType.id
                      )
                    }
                  />
                  <label>
                    ${ticket.amount}: {ticket.ticketType.name}
                  </label>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default SlidingScale
