import React, { useEffect, useReducer } from "react"
import donationReducer, {
  AMOUNT,
  initialDonationState,
} from "../../routes/Donations/donationReducer"
import {
  getTotalQuantity,
  submitTicketPayload,
} from "../../utilities/eventTools"
import { useDonateApi, usePostEventTicket } from "../../utilities"

import BasketAddDonation from "../BasketAddDonation"
import CalendarDatePicker from "../CalendarDatePicker"
import FixedPrice from "./FixedPrice"
import { Link } from "react-router-dom"
import SlidingScale from "./SlidingScale"
import { Spinner } from "reactstrap"
import TimeSelection from "../PicaEvent/TimeSelection"
import { getTimeSelection } from "../PicaEvent/utils"
import moment from "moment"
import { useGetBasketApi } from "../../utilities"
import { useHistory } from "react-router"

const BuyTicket = ({
  eventName,
  eventRoute,
  venue,
  venues,
  eventsInstancesStatus,
  eventsInstancesData,
  eventInstance,
  setEventInstance,
  ticketType,
  ticketDetails,
  ticketDetailsRich,
  setTicketType,
  seatingPlanId,
  setSeatingPlanId,
  quantity,
  slidingAmounts,
  setSlidingAmounts,
  ticketData,
  ticketStatus,
  eventStatusData,
  eventStatusLoading,
}) => {
  const [donationState, donationDispatch] = useReducer(
    donationReducer,
    initialDonationState
  )
  const { data: basketData } = useGetBasketApi()

  const { mutate: addTicket, isLoading: isPostTickLoading } =
    usePostEventTicket()

  const { post: addDonation } = useDonateApi()
  const history = useHistory()

  const isSlidingScale = ticketData && ticketData.prices?.length > 1
  const isFixedPrice = ticketData && ticketData.prices?.length === 1

  useEffect(() => {
    donationDispatch({
      type: AMOUNT,
      payload: "5",
    })
  }, [])

  // Set ticketType when there is only one price option.
  // If more price options a list of buttons will show to the user to select.
  useEffect(() => {
    if (ticketData?.prices.length > 0) {
      setTicketType(ticketData.prices[0].ticketType.id)
    }
  }, [ticketData, setTicketType, ticketType])

  const handleSubmitTicket = (isMakingDonation) => {
    if (isMakingDonation) {
      addDonation(donationState)
    }

    const TicketPayloadProps = {
      isFixedPrice,
      isSlidingScale,
      slidingAmounts,
      quantity,
      eventInstance,
      seatingPlanId,
      ticketType,
    }

    addTicket(submitTicketPayload(TicketPayloadProps), {
      onSuccess: () => {
        history.push("/cart")
      },
    })
  }

  const ticketsAvailable = parseInt(eventStatusData?.available)
  const isSoldOut = ticketsAvailable <= 0

  const handleSlidingScaleUpdate = (e, amount, id, ticketType) => {
    const quantity = e.target.value
    const newSlidingAmounts = {
      ...slidingAmounts,
      [id]: { quantity, id, ticketType },
    }
    const totalQuantityOfSelected = getTotalQuantity(newSlidingAmounts)
    if (totalQuantityOfSelected <= ticketsAvailable) {
      setSlidingAmounts(newSlidingAmounts)
    }
  }
  const hasAddedATicket =
    (isSlidingScale &&
      slidingAmounts &&
      getTotalQuantity(slidingAmounts) > 0) ||
    (isFixedPrice && quantity && quantity > 0)

  const isBuyBtnDisabled =
    isPostTickLoading || !eventInstance || !hasAddedATicket || isSoldOut

  const timeOptions = getTimeSelection(eventsInstancesData, eventInstance)
  return (
    <div className="flex_full">
      <article>
        <header className="tickets-header">
          <div className="field">
            <h1>PICA Box Office</h1>
            <p className="not-tba">
              For questions please email{" "}
              <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or call{" "}
              <a href="tel:503-224-7422">503-224-PICA(7422)</a>
            </p>
            <p className="tba-only">
              For questions about tickets, passes, accessibility, or to request
              access services please email{" "}
              <a href="mailto:BoxOffice@pica.org">BoxOffice@pica.org</a> or call{" "}
              <a href="tel:503-224-7422">503-224-PICA(7422)</a>
            </p>
          </div>
          <div className="field login-field">
            {/*<button className="button">Login</button>*/}

            <Link to="/cart" className="cart-icon">
              <i className="item-icons fas fa-shopping-cart"></i>
              <b>
                {basketData && basketData?.total && (
                  <span>${basketData?.total}</span>
                )}
              </b>
            </Link>
          </div>
        </header>

        <a className="accessibility-banner access-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>
        <a className="accessibility-banner visit-banner" href="/about/visit" target="_blank">Click here for Safety Protocols, Accessibility, or to Request Access Services</a>

        <main className="tickets-main">
          <div className="field">
            <h2>{eventName}</h2>
            {venues.map((venue)=>
              <p className="no-margin">
                <span>{venue.name} </span>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${venue.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {venue.address}
                </a>
              </p>
            )}
          </div>

          <div className="ticket-details">
            <div className="ticket-col calendar-col">
              <h3>Dates</h3>

              {eventsInstancesStatus === "loading" && <p>...Getting Dates</p>}

              {eventsInstancesData && (
                <CalendarDatePicker
                  events={eventsInstancesData}
                  setEventInstance={setEventInstance}
                  setSeatingPlanId={setSeatingPlanId}
                  eventInstance={eventInstance}
                />
              )}

              <Link to={eventRoute} className="button back-event-button">
                Back to event
              </Link>
            </div>

            <div className="ticket-col">
              <div className="tickets-field">
                <h3>Tickets</h3>
                <p className="-sm_lineheight">Ticket pre-sale ends two hours before each event. Tickets are available at the door each night for most events.</p>
                {/* /////// No Selected Ticket \\\\\\\\\ */}
                {ticketStatus === "loading" && !eventInstance && (
                  <p>
                    <b>DATE:</b> Select Ticket to see price{" "}
                  </p>
                )}
                {/* /////// Loading Ticket Data \\\\\\\\\ */}
                {ticketStatus === "loading" &&
                  eventInstance &&
                  eventInstance?.isFuture && <p> Getting Price... </p>}

                {!eventInstance?.isFuture && (
                  <p>This event has already passed. </p>
                )}

                {isSoldOut && <p className="yellow">Sold Out</p>}

                {eventInstance?.isFuture && !isSoldOut && (
                  <div className="field">
                    <div className="field dates-field">
                      <p>
                        <b>DATE: </b>
                        {moment(eventInstance.dateString).format(
                          "MMMM D, YYYY"
                        )}
                        , {eventInstance.time} PST
                      </p>
                    </div>

                    {timeOptions?.length && (
                      <TimeSelection
                        timeOptions={timeOptions}
                        setEventInstance={setEventInstance}
                        setSeatingPlanId={setSeatingPlanId}
                        eventInstance={eventInstance}
                      />
                    )}
                    {isSlidingScale && (
                      <SlidingScale
                        eventStatusLoading={eventStatusLoading}
                        ticketsAvailable={ticketsAvailable}
                        ticketData={ticketData}
                        slidingAmounts={slidingAmounts}
                        handleSlidingScaleUpdate={handleSlidingScaleUpdate}
                      />
                    )}
                    {isFixedPrice && (
                      <FixedPrice
                        eventStatusLoading={eventStatusLoading}
                        ticketsAvailable={ticketsAvailable}
                        ticketData={ticketData}
                        slidingAmounts={slidingAmounts}
                        handleSlidingScaleUpdate={handleSlidingScaleUpdate}
                      />
                    )}
                    {ticketDetails && !ticketDetailsRich && (
                      <div className="field details-field">
                        <p>
                          <b>DETAILS: </b>
                          {ticketDetails}
                        </p>
                      </div>
                    )}
                    {ticketDetailsRich && (
                      <div className="field details-field">
                        <p>
                          <b>DETAILS: </b>
                        </p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ticketDetailsRich,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <BasketAddDonation
                donationState={donationState}
                donationDispatch={donationDispatch}
              />
              <div className="ticket-buttons">
                {eventInstance?.isFuture && (
                  <>
                    <button
                      className={`button`}
                      onClick={() => handleSubmitTicket(false)}
                      disabled={isBuyBtnDisabled}
                    >
                      Continue Without Donation
                    </button>
                    <button
                      className="button"
                      onClick={() => handleSubmitTicket(true)}
                      disabled={isBuyBtnDisabled}
                    >
                      Continue {isPostTickLoading && <Spinner size={"sm"} />}
                    </button>
                  </>
                )}
                {!eventInstance?.isFuture && (
                  <button className="button" disabled>
                    Event has happened
                  </button>
                )}
              </div>
            </div>
            {/* ticket-col */}
          </div>
          {/* ticket-details */}
        </main>
      </article>
    </div>
  )
}

export default BuyTicket
