import { makePayload } from "../components/PicaEvent/makePayload"

export const submitTicketPayload = (props) => {
  const {
    isFixedPrice,
    isSlidingScale,
    slidingAmounts,
    quantity,
    eventInstance,
    seatingPlanId,
    ticketType,
  } = props

  if (isFixedPrice && quantity) {
    const payload = makePayload(
      quantity,
      eventInstance?.eventData?.id || eventInstance,
      seatingPlanId,
      ticketType
    )
    return [payload]
  }

  if (
    isSlidingScale &&
    slidingAmounts &&
    getTotalQuantity(slidingAmounts) > 0
  ) {
    let payload = []
    Object.keys(slidingAmounts).forEach(
      (price) =>
        (payload = [
          ...payload,
          ...makePayload(
            slidingAmounts[price].quantity,
            eventInstance?.eventData?.id || eventInstance,
            seatingPlanId,
            slidingAmounts[price].ticketType
          ),
        ])
    )
    return payload
  }
}

export function getTotalQuantity(slidingAmountsObject) {
  return Object.values(slidingAmountsObject)
    .map((item) => item?.quantity)
    .reduce((accumulator, el) => {
      return parseInt(el) + accumulator
    }, 0)
}

export const getQuantityValue = (amount, slidingAmounts, id) => {
  if (slidingAmounts && slidingAmounts[id]) {
    const quantity = slidingAmounts[id].quantity
    if (quantity < 0) {
      return 0
    }
    return quantity
  }
  return 0
}
