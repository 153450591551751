import React from 'react'
import { Query } from 'react-apollo'
import styles from './styles.module.sass'
import gql from 'graphql-tag'
import moment from 'moment'

import PicaEventListItem from '../PicaEventListItem'
import PicaPastEventListItem from '../PicaEventListItem/PastEvents'

class PicaEventQuery extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 100,
      lastIndex: 0,
      cardOpen: false
    }
    this.setOpen = this.setOpen.bind(this)
    this.setClose = this.setClose.bind(this)
  }

  setOpen(index) {
    this.setState({
      cardOpen: true,
      activeIndex: index
    })
  }
  setClose(index) {
    this.setState({
      cardOpen: false,
      lastIndex: index
    })
  }

  addInOrder(programList, program){
    if(programList.length === 0 && program){
      programList.push({
        title: program.title,
        artist: program.artist[0] ? program.artist[0].name : '',
        image: program.gallery ? 'https://media.graphassets.com/' + program.gallery.galleryItems[0].media.handle: "",
        description: program.shortDescription,
        tags: program.tags,
        route: program.route,
        sortTimeVal: program.sortTimeVal,
        dateAndTime: moment(program.dateAndTime[0]).format("LLL")
      })
    } else {
      for(let i = 0; i < programList.length; i++){
        if(program.sortTimeVal < programList[i].sortTimeVal){
          programList.splice(i, 0, {
            title: program.title,
            artist: program.artist[0] ? program.artist[0].name : '',
            image: program.gallery ?  'https://media.graphassets.com/' + program.gallery.galleryItems[0].media.handle: "",
            tags: program.tags,
            route: program.route,
            description: program.shortDescription,
            sortTimeVal: program.sortTimeVal,
            dateAndTime: moment(program.dateAndTime[0]).format("LLL")
          })
          break;
        }
        if(i === (programList.length - 1)){
          programList.push({
            title: program.title,
            artist: program.artist[0] ? program.artist[0].name : '',
            image: program.gallery ? 'https://media.graphassets.com/' + program.gallery.galleryItems[0].media.handle: "",
            tags: program.tags,
            route: program.route,
            description: program.shortDescription,
            sortTimeVal: program.sortTimeVal,
            dateAndTime: moment(program.dateAndTime).format("LLL")
          })
          break;
        }
      }
    }
  }

  formatData(programs){
    let today = moment();
    console.log(programs);
    const formatted = programs.filter((program) => moment(program.dateAndTime[program.dateAndTime.length - 1]).isAfter(today)).map((program) => {
      return {
        title: program.title,
        artist: program.artist[0] ? program.artist.map((artist)=> artist.name).join(', ') : '',
        image: program.gallery ? 'https://media.graphassets.com/' + program.gallery.galleryItems[0].media.handle: "",
        tags: program.tags,
        route: program.route,
        description: program.shortDescription,
        sortTimeVal: program.sortTimeVal,
        dateAndTime: program.dateAndTime
      }
    })

    const pastFormatted = programs.filter((program) => moment(program.dateAndTime[program.dateAndTime.length - 1]).isBefore(today)).map((program) => {
      return {
        title: program.title,
        artist: program.artist[0] ? program.artist.map((artist)=> artist.name).join(', ') : '',
        image: program.gallery ? 'https://media.graphassets.com/' + program.gallery.galleryItems[0].media.handle: "",
        tags: program.tags,
        route: program.route,
        description: program.shortDescription,
        sortTimeVal: program.sortTimeVal,
        dateAndTime: program.dateAndTime
      }
    })
    const sortedByDate = formatted.sort((a, b) => new Date(a.dateAndTime[0]) - new Date(b.dateAndTime[0]))
    const sortedByDatePast = pastFormatted.sort((a, b) => new Date(a.dateAndTime[0]) - new Date(b.dateAndTime[0]))

    return {
      future: formatted,
      past: pastFormatted
    }
  }

  importFilterAndSort(programs){
    //Date filtering starts with a date object that takes current Date, then gets the ISOStrings for the beginning of the day, the end of the day, and the end of the week
    let d = new Date('2019-09-06T01:30:00.000Z')
    d.setHours(0)
    d.setMinutes(0)
    let daystart = d.toISOString()
    let dayval = d.getDay()
    d.setDate(d.getDate() + 1)
    let dayend = d.toISOString()
    d.setDate(d.getDate() + (7 - ((dayval-1) % 7)))
    let weekend = d.toISOString()
    let eventsTodayData = []
    let eventsThisWeekData = []
    let eventsUpcomingData = []
    for (let i = 0; i < programs.length; i++) {
      let today = false
      let thisweek = false
      let upcoming = false
      let sortTimeVal = ''
      for (let j = 0; j < programs[i].dateAndTime.length; j++) {
        if(programs[i].dateAndTime[j] < daystart){
          break;
        }
        if(programs[i].dateAndTime[j] < dayend){
          today = true
          sortTimeVal = programs[i].dateAndTime[j]
          break;
        }
        if(programs[i].dateAndTime[j] < weekend){
          thisweek = true
          sortTimeVal = programs[i].dateAndTime[j]
          break;
        }
        if(programs[i].dateAndTime[j] > weekend){
          upcoming = true
          sortTimeVal = programs[i].dateAndTime[j]
        }
      }
      if ((!today && !thisweek) && !upcoming){
        continue;
      }
      let program = programs[i]
      program.sortTimeVal = sortTimeVal
      if(today){
        this.addInOrder(eventsTodayData, program)
        continue;
      }
      if(thisweek){
        this.addInOrder(eventsThisWeekData, program)
        continue;
      }
      if(upcoming){
        this.addInOrder(eventsUpcomingData, program)
      }
    }
    let data = {
      eventsTodayData,
      eventsThisWeekData,
      eventsUpcomingData
    }
    return data
  }

  setBackgroundColor(index){
    const i = index % 6
    if(i === 0){
      return '#B0C170'
    }
    if(i === 1){
      return '#0DAE5D'
    }
    if(i === 2){
      return '#8EA9D5'
    }
    if(i === 3){
      return '#FFC2EB'
    }
    if(i === 4){
      return '#FF5928'
    }
    if(i === 5){
      return '#8A69D4'
    }
  }

  shouldComponentUpdate(prevProps){
    if(this.props.searchTerm !== prevProps.searchTerm){
      this.setState({
        cardOpen: false
      })
      return true
    }
    return true
  }

  render(){
    //console.log(this.props.searchTerm)
    const SEARCH_CONTAIN = gql`
      query EventSearchByTitleandArtist($searchTerm : String){
        programs(where: {
          OR: [{title_contains: $searchTerm}, {artist_some: {name_contains: $searchTerm}}]
        }, last: 1000){
          title
          artist {
            name
          }
          dateAndTime
          shortDescription
          route
          gallery {
            galleryItems {
              media {
                handle
              }
            }
          }
          tags {
            name
          }
        }
      }
    `
    const SEARCH_TAG = gql`
      query EventbyTagName($searchTerm : String){
        programs(where: {tags_some: {name: $searchTerm}}){
          dateAndTime
          title
          artist {
            name
          }
          dateAndTime
          shortDescription
          route
          gallery {
            galleryItems {
              media {
                handle
              }
            }
          }
          tags {
            name
          }
        }
      }
    `
    return(
      <Query query={(this.props.searchType === 'tag') ? SEARCH_TAG : SEARCH_CONTAIN} variables={{"searchTerm" : this.props.searchTerm}}>
        {({ loading, error, data }) => {
          if (loading) return (
            <h1>Loading&hellip;</h1>
          )
          if (error) return `Error! ${error.message}`
          console.log(data.programs)
          const formatted = this.formatData(data.programs)
          let programData = this.importFilterAndSort(data.programs)
          let eventsUpcoming = formatted.future.map((program, index) =>
            <PicaEventListItem
              title={program.title}
              artist={program.artist}
              tags={program.tags}
              route={program.route}
              key={index + programData.eventsTodayData.length + programData.eventsThisWeekData.length}
              index={index + programData.eventsTodayData.length + programData.eventsThisWeekData.length}
              activeIndex={this.state.activeIndex}
              setOpen={this.setOpen}
              setClose={this.setClose}
              cardOpen={this.state.cardOpen}
              image={program.image}
              description={program.description}
              background={this.setBackgroundColor(index + programData.eventsTodayData.length + programData.eventsThisWeekData.length)}
              time={program.dateAndTime}
            />
          )
          let eventsPast = formatted.past.map((program, index) =>
            <PicaEventListItem
              title={program.title}
              artist={program.artist}
              tags={program.tags}
              route={program.route}
              key={index + programData.eventsTodayData.length + programData.eventsThisWeekData.length}
              index={index + programData.eventsTodayData.length + programData.eventsThisWeekData.length}
              activeIndex={this.state.activeIndex}
              setOpen={this.setOpen}
              setClose={this.setClose}
              cardOpen={this.state.cardOpen}
              image={program.image}
              description={program.description}
              background={this.setBackgroundColor(index + programData.eventsTodayData.length + programData.eventsThisWeekData.length)}
              time={program.dateAndTime}
            />
          )
          return (
            <div className={styles.PicaEvents}>
              {/*<!--
                <PicaEventListItem section index={0} activeIndex={this.state.activeIndex} cardOpen={this.state.cardOpen}>
                <h4>TODAY</h4>
              </PicaEventListItem>
              {eventsToday}
              <PicaEventListItem section index={eventsToday.length} activeIndex={this.state.activeIndex} cardOpen={this.state.cardOpen}>
                <h4>THIS WEEK</h4>
              </PicaEventListItem>
              {eventsThisWeek}
              -->*/}
              <PicaEventListItem section index={0} activeIndex={this.state.activeIndex} cardOpen={this.state.cardOpen}>
                <h4>UPCOMING</h4>
              </PicaEventListItem>

              <div className="events-flex upcoming-events">
                {eventsUpcoming}
              </div>
              <PicaPastEventListItem section index={0} activeIndex={this.state.activeIndex} cardOpen={this.state.cardOpen}>
                <h4>PAST EVENTS</h4>
              </PicaPastEventListItem>
              <div className="events-flex -reverse">
                {eventsPast}
              </div>

            </div>
          )
        }}
      </Query>
    )
  }
}

export default PicaEventQuery
